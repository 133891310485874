import { langCodes } from "../../_common/lang-codes.js";
import type { LangCode, Thumbnail } from "../../_common/types.js";
import { AppPlatform } from "../graphql/queries/apps.js";

// TODO: next function needs logic improve
export const makeIconUrl = (url: string, sizes: string[]): string =>
    `${url}${[...sizes].reverse().find(size => Number(size.slice(1)) > 120)}`;

export const makeAspectRatio = (thumbnails: Thumbnail[]) => {
    const { width, height } = thumbnails[0];
    return `${width}:${height}`;
};

const convertToAppstoreLang = (lang: LangCode): string => {
    switch (lang) {
        case "en":
            return "";
        case "ja":
            return "/jp";
        case "ko":
            return "/kr";
        case "zh-Hans":
            return "/cn";
        case "zh-Hant":
            return "/tw";
        default:
            return "/" + lang;
    }
};

export const trackMarketUrl = (
    baseUrl: string,
    platform: AppPlatform,
    lang: LangCode,
    location: string,
    item: string,
    variant?: number,
): string => {
    const _item = typeof variant !== "undefined" ? `${item}-${variant}` : item;
    const url = new URL(baseUrl);
    if (platform === "android") {
        if (lang !== "en") {
            url.searchParams.set("hl", lang);
        }
        url.searchParams.set(
            "referrer",
            `utm_source=swsite-${lang}-${location}&utm_medium=${_item}&utm_campaign=${_item}`,
        );
    }
    if (platform === "ios") {
        url.pathname = `${convertToAppstoreLang(lang)}${url.pathname}`;
        url.searchParams.set("mt", "8"); // mt equals 8 means that it is mobile application
        url.searchParams.set("pt", "19853"); // 19853 - it is Vito Technology's AppStore ID it is forever constant
        url.searchParams.set("ct", `swsite-${lang}/${location}:${_item}`);
    }
    // TODO: research url structure
    // if (platform === "huawei") {
    //     url.searchParams.set("campaign_channel", "vitosite");
    //     url.searchParams.set("campaign_medium", `${item}`);
    //     url.searchParams.set("campaign_name", `${location}`);
    // }
    return url.toString();
};

export const glsl = (code: TemplateStringsArray): string => `${code}`;

export const isClient = (): boolean => {
    if (typeof window !== "undefined") {
        return true;
    }
    return false;
};

export const getDevicePlatform = (): AppPlatform | null => {
    try {
        if (typeof window !== "undefined" && typeof navigator !== "undefined") {
            const userAgent =
                navigator.userAgent || navigator.vendor || navigator.platform;

            if (/android/i.test(userAgent)) {
                return "android";
            }

            if (/iPad|iPhone|iPod/.test(userAgent)) {
                return "ios";
            }

            return null;
        }
        return null;
    } catch (e) {
        return null;
    }
};

export const abbrevieteNumber = (value: number): string => {
    const suffixes = ["", "K", "M", "G", "T", "P", "E"];
    if (value < 1000) {
        return `${value}`;
    }
    let newValue = value;
    let suffixIndex = 0;
    while (newValue >= 1000) {
        newValue /= 1000;
        suffixIndex++;
    }
    const fixed = newValue.toFixed(1);
    const floored = Math.floor(newValue);
    const final =
        `${floored}`.length <= 1 && Number(fixed) > floored ? fixed : floored;
    return `${final}${suffixes[suffixIndex]}`;
};

export const getLocalStorageItem = (item: string) => {
    try {
        return localStorage.getItem(item);
    } catch (error) {
        return null;
    }
};

export const setLocalStorageItem = (item: string, key: string) => {
    try {
        localStorage.setItem(item, key);
        return null;
    } catch (error) {
        return null;
    }
};

export const removeLocalStorageItem = (key: string) => {
    try {
        localStorage.removeItem(key);
        return null;
    } catch (error) {
        return null;
    }
};

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const windowLoaded = async (): Promise<void> => {
    if (document.readyState === "complete") return;
    await new Promise(function (resolve) {
        window.addEventListener("load", resolve);
    });
};

export const sleep = async (s: number): Promise<number> =>
    new Promise(resolve => {
        setTimeout(() => resolve(s), s * 1000);
    });

export const randomSleep = async (
    minSec: number,
    maxSec: number,
): Promise<number> => {
    const s = Math.floor(Math.random() * (maxSec - minSec) + minSec);
    return sleep(s);
};

export const getTimezoneOffset = () => {
    return new Date().getTimezoneOffset() * -1;
};

export const getWeekDays = (langCode: LangCode): string[] => {
    const baseDate = new Date("2024-01-01T01:00:00.000Z"); // first day is Monday for this date
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
        weekDays.push(
            baseDate.toLocaleDateString(langCode, {
                weekday: "short",
                timeZone: "UTC",
            }),
        );
        baseDate.setUTCDate(baseDate.getDate() + 1);
    }
    return weekDays;
};

export const abbrevieteDegrees = (deg: number): string => {
    const _deg = Math.floor(deg);
    const min = (deg - _deg) * 60;
    const _min = Math.floor(min);
    const sec = ((min - _min) * 60).toFixed(1);

    return `${_deg}° ${_min}' ${sec.padStart(4, "0")}"`;
};

export const langsReg = new RegExp(`^/(${langCodes.join("|")})`);

export const extractLangAndCPath = (
    pathname: string,
): { lang: LangCode | ""; cpath: string } => {
    const match = pathname.match(langsReg);
    return {
        lang: match ? (match[1] as LangCode) : "",
        cpath: pathname.replace(langsReg, "") || "/",
    };
};
